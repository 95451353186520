import { SVLRouteValue } from '@domain/shared/svl-route-value';
import { AppRouteNames } from 'src/app/app.routenames';

interface AccountRouteNamesType {
  myAccount: SVLRouteValue;
}

export const AccountRouteNames: AccountRouteNamesType = {
  myAccount: {
    local: 'my-account',
    global: `${AppRouteNames.account}/my-account`,
  },
};